import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Home from './Home/Home'
import Appointment from './Appointment/Appointment'
import AppointmentMissed from './AppointmentMissed/AppointmentMissed'
import AppointmentPlanning from './AppointmentPlanning/AppointmentPlanning'
import AppointmentProblem from './AppointmentProblem/AppointmentProblem'
import AppointmentCheck from './AppointmentCheck/AppointmentCheck'
import AppointmentTracking from './AppointmentTracking/AppointmentTracking'
import Help from './Help/Help'
import RateUs from './RateUs/RateUs'
import Contact from './Contact/Contact'
import Faq from './Faq/Faq'
import DownloadCenter from './DownloadCenter/DownloadCenter'
import Newsletter from './Newsletter/Newsletter'
import QRScreen from './QRScreen'
import Livechat from './Livechat'
import NotFound from './NotFound/NotFound'
import Imprint from './Imprint'
import Privacy from './Privacy'
import LiveTracking from './LiveTracking'

import { HomeLayout, LangLayout } from '../layouts'

import { AvailableRoutes } from '../configs/RouteConfig'
import LivechatZammad from './Livechat/LivechatZammad'
import AppointmentSchedule from "./AppointmentSchedule/AppointmentSchedule";

const AppRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<HomeLayout />}>
        <Route index element={<Home />} />

        <Route path=':lang' element={<LangLayout />}>
          <Route index element={<Home />} />

          <Route path={AvailableRoutes.appointment} element={<Appointment />} />

          <Route
            path={AvailableRoutes.appointmentMissed.index}
            element={<AppointmentMissed />}
          />

          <Route
            path={AvailableRoutes.appointmentPlanning.index}
            element={<AppointmentPlanning />}
          />

          <Route
            path={AvailableRoutes.appointmentProblem.index}
            element={<AppointmentProblem />}
          />

          <Route
            path={AvailableRoutes.appointmentCheck.index}
            element={<AppointmentCheck />}
          />

          <Route
            path={AvailableRoutes.appointmentTracking.index}
            element={<AppointmentTracking />}
          />

          <Route
            path={AvailableRoutes.appointmentSchedule.index}
            element={<AppointmentSchedule />}
          />

          <Route
            path={AvailableRoutes.appointmentScheduleShortPath.index}
            element={<AppointmentSchedule />}
          />

          <Route path={AvailableRoutes.help} element={<Help />} />

          <Route
            path={AvailableRoutes.downloadCenter}
            element={<DownloadCenter />}
          />

          <Route path={AvailableRoutes.faq} element={<Faq />} />

          <Route path={AvailableRoutes.rateUs} element={<RateUs />} />

          <Route path={AvailableRoutes.contact} element={<Contact />} />

          <Route path={AvailableRoutes.newsletter} element={<Newsletter />} />

          <Route path={AvailableRoutes.qr} element={<QRScreen />} />
          <Route
            path={AvailableRoutes.liveTracking}
            element={<LiveTracking />}
          />

          <Route path={AvailableRoutes.imprint} element={<Imprint />} />

          <Route path={AvailableRoutes.privacy} element={<Privacy />} />

          <Route path={AvailableRoutes.livechat} element={<Livechat />} />

          <Route path='*' element={<NotFound />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default AppRouter
