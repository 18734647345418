import React from 'react'
import PropTypes from 'prop-types'
import {Col, Row} from 'react-grid-system'
import {useFormik} from 'formik'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'

import {Spinner} from 'pyrexx-react-library'
import Navigator from '../../Navigator/Navigator'
import {useSearchParams} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import isObjectValid from "../../../helper/isObjectValid";
import ModalPopup from "../../ModalPopup/ModalPopup";
import {
  StyledWelcome
} from "../../../routes/AppointmentSchedule/AppointmentSchedule.styles";

const GET_APPOINTMENT_BY_ID = gql`
query AppointmentById($encryptedAppointmentId: String!) {
  getAppointmentInformation(encryptedAppointmentId: $encryptedAppointmentId) {
    validation {
      success
      systemMessage
    }
    appointmentType
    serviceCategory
    street
    zip
    town
    floor
    lastName
    firstName
    companyName
  }
}
`
const AppointmentOfferMessage = (props) => {
  const {savedValues = {}, saveFormInputs, nextStep} = props
  const [search] = useSearchParams()
  const {t} = useTranslation()
  const navigate = useNavigate()

  let {appointmentId} = useParams();
  if(appointmentId === undefined) {
    appointmentId = search.get('appointmentId') || '';
  }
  const formik = useFormik({
    initialValues: {
      appointmentId: appointmentId,
    },
    onSubmit: (values) => {
      saveFormInputs(values)
      navigate(nextStep)
    },
  })

  const variables = {
    encryptedAppointmentId: appointmentId,
  }

  let errorMessage = ''

  const {data, loading, error} = useQuery(GET_APPOINTMENT_BY_ID, {
    variables,
    fetchPolicy: 'no-cache',
  })

  if (loading) return <Spinner/>
  if (error) {
    errorMessage = t(
      'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
    )
  }

  const tfs = data?.getAppointmentInformation || {}

  const {
    validation,
    street,
    zip,
    town,
    floor,
    firstName,
    lastName,
    serviceCategory,
    companyName
  } = tfs

  savedValues.street = street
  savedValues.zip = zip
  savedValues.town = town
  savedValues.floor = floor
  savedValues.firstName = firstName
  savedValues.lastName = lastName
  savedValues.serviceCategory = serviceCategory
  savedValues.companyName = companyName

  if (!errorMessage && !isObjectValid(tfs)) {
    errorMessage = t(
      'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
    )
  }

  if (!errorMessage && !validation) {
    errorMessage = t(
      'SORRY SINCE WE SENT YOU THE MESSAGE ALL APPOINTMENT SLOTS HAVE BEEN BOOKED, WE WILL CONTACT YOU AGAIN',
    )
  }

  if (!errorMessage && !validation.success) {
    errorMessage = t(
      'SORRY SINCE WE SENT YOU THE MESSAGE ALL APPOINTMENT SLOTS HAVE BEEN BOOKED, WE WILL CONTACT YOU AGAIN',
    )
  }

  if (!errorMessage && !validation.success && (validation.systemMessage === 'APPOINTMENT IS ALREADY SCHEDULED' || validation.systemMessage === 'APPOINTMENT NOT FOUND')) {
    errorMessage = t(
      validation.systemMessage
    )
  }

  if (errorMessage) {
    return (
      <ModalPopup
        isOpen
        showButtonClose={false}
        noteText={<p>{errorMessage}</p>}
        handleModalClose={() => {
        }}
        onClick={() => navigate('/')}
        buttonText={t('CLOSE')}
      />
    )
  } else {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Row justify='center'>
          <Col>

            <StyledWelcome>
              {t(`DEAR SIR OR MADAM,

ON BEHALF OF {COMPANY_NAME}, WE WOULD LIKE TO SCHEDULE AN APPOINTMENT WITH YOU ({NAME} {ADDRESS}) FOR {SERVICE}.
PLEASE SELECT ONE OF THE PROPOSED APPOINTMENTS BELOW. IF NONE OF THE DATES ARE SUITABLE, WE WILL NEED TO DISCUSS THIS OVER THE PHONE.
PLEASE NOTE THAT IN SOME CASES, COSTS MAY BE INCURRED THAT WILL BE PASSED ON TO YOU BY THE PROPERTY MANAGEMENT.

KIND REGARDS,
YOUR PYREXX TEAM`, {
                COMPANY_NAME: savedValues.companyName.trim(),
                NAME: savedValues.firstName.trim() + ' ' + savedValues.lastName.trim(),
                ADDRESS: savedValues.street.trim() + ' ' + savedValues.zip.trim() + ', ' + savedValues.town.trim(),
                SERVICE: savedValues.serviceCategory.trim()
              })}

            </StyledWelcome>

          </Col>
        </Row>

        <Navigator nextButtonText={t('SELECT SLOTS')} />
      </form>
    )
  }
}

AppointmentOfferMessage.propTypes = {
  savedValues: PropTypes.object,
  saveFormInputs: PropTypes.func.isRequired,
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
}

export default AppointmentOfferMessage
