import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { gql, useQuery } from '@apollo/client'

import { Spinner } from 'pyrexx-react-library'
import ModalPopup from '../ModalPopup/ModalPopup'
import SlotSelectionInput from '../Steps/Inputs/SlotSelectionInput'

import isObjectValid from '../../helper/isObjectValid'

const GET_FREE_TIMEFRAMES = gql`
  query SlotSelectionContainerQuery($validation: AppointmentChangeValidation!) {
    getAppointmentChangeFreeTimeFrames(validation: $validation) {
      validation {
        success
        systemMessage
        errorType
      }
      validatedEncryptedApartmentId
      freeTimeFrames {
        from
        to
      }
      validAddress
    }
  }
`

const SlotSelectionContainer = (props) => {
  const { savedValues, saveFormInputs, prevStep, nextStep } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const variables = {
    validation: {
      serviceCategory: savedValues.serviceCategory,
      appointmentType: savedValues.appointmentType,
      appointmentId: savedValues.appointmentId,
      firstName: savedValues.firstName,
      lastName: savedValues.lastName,
      street: savedValues.street,
      floor: savedValues.floor,
      zip: savedValues.zip,
      town: savedValues.town,
    },
  }

  let errorMessage = ''

  const { data, loading, error } = useQuery(GET_FREE_TIMEFRAMES, {
    variables,
    fetchPolicy: 'no-cache',
  })

  if (loading) return <Spinner />
  if (error) {
    errorMessage = t(
      'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
    )
  }

  const tfs = data?.getAppointmentChangeFreeTimeFrames || {}

  const {
    freeTimeFrames: slots,
    validatedEncryptedApartmentId = '',
    validation,
    validAddress,
  } = tfs

  let isMareonMessage = t('SORRY YOU NEED TO CONTACT YOUR FACILITY MANAGEMENT, PYREXX IS NOT ALLOWED TO HANDLE ENQUIRIES DIRECTLY');
  if(validation && validation.systemMessage === isMareonMessage) {
    errorMessage = isMareonMessage;
  }

  if (!errorMessage && !isObjectValid(tfs)) {
    errorMessage = t(
      'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
    )
  }

  if (!errorMessage && !validation) {
    errorMessage = t(
      'UNFORTUNATELY WE COULD NOT FIND YOUR ADDRESS. PLEASE CHECK YOUR DETAILS AGAIN OR CALL SUPPORT.',
    )
  }

  if (!validAddress && validation) {
    errorMessage = t(validation.systemMessage)
  }

  if (validation && validation.errorType !== undefined && validation.errorType === 'user') {
    errorMessage = t(
      validation.systemMessage,
    )
  }

  if (errorMessage) {
    return (
      <ModalPopup
        isOpen
        showButtonClose={false}
        noteText={<p>{errorMessage}</p>}
        handleModalClose={() => {}}
        onClick={() => navigate('/')}
        buttonText={t('CLOSE')}
      />
    )
  } else {
    return (
      <SlotSelectionInput
        savedValues={savedValues}
        saveFormInputs={saveFormInputs}
        prevStep={prevStep}
        nextStep={nextStep}
        slots={slots}
        validatedEncryptedApartmentId={validatedEncryptedApartmentId}
      />
    )
  }
}

export default SlotSelectionContainer
